import "../css/FAQ.css";

export default function FAQ() {
    return (<div className="FAQ">
        <div className="section__content">
            <div className="header">
                <h1>Car Insurance FAQ<span>.</span></h1>

                <div className="body">
                    <br />
                    <h5>1. I have a comprehensive insurance. What will it cover?</h5>
                    <div>A comprehensive insurance policy protects against loss, damage and includes third party liability. Additionally, you also get cover against damage to the vehicle due to accidental collision, overturning, fire, external explosion, self-ignition, theft, or due to vandalism unless specifically excluded.</div>

                    <h5>2. I have a third party liability insurance. What will it cover?</h5>
                    <div>Third party insurance policy is a basic compulsory insurance in UAE which provides cover against death or bodily injury to any third party as well as property damage by insured vehicle. The policy does not cover damage to the insured vehicle. In UAE third party liability will generally be the only option if your car is 7 years or older.</div>

                    <h5>3. What is no claim discount (NCD) or No claim bonus?</h5>
                    <div>It is a discount given by your service provider on your premium. It takes into account your driving experience and the number of years you have been driving without any claims.</div>

                    <h5>4. What is excess deductible?</h5>
                    <div>It is the amount you pay to the insurance company in case of a non-recoverable claim. Non-recoverable motor claims unusually occur when an accident is caused by the insurance policy holder. This is only applicable for comprehensive insurance. If you get into an accident and it is not your fault then you don't have to pay any excess.</div>

                    <h5>5. Why is the validity of my insurance 13 months?</h5>
                    <div>The general registration period of your car is 12 months. However, road and traffic authority (RTA) provides a grace period of 1 month to complete the annual registration process. The grace period is also covered in the policy.</div>

                    <h5>6. What is agency and non-agency repair?</h5>
                    <div>If you opt for agency repairs, you can get your vehicle repaired at the manufacturer’s authorized workshop in case of an accident. In the case of non-agency repair, your vehicle can be repaired at any of the authorized garages of the insurance company.</div>

                    <h5>7. How can I make a claim?</h5>
                    <div>If you got into an accident and want to claim for the damages incurred, get the police report and contact insurance company as soon as you can and inform them about the accident. The insurance company will ask to take your car to the workshop for assessment and provide you with a reference number for your claim. The workshop will then give an estimated cost of repair which needs to be approved by the insurance company. Upon approval, the garage will contact you when your vehicle is ready for pick up.</div>

                    <h5>8. Is anyone who drives my car covered?</h5>
                    <div>This depends on the insurance policy terms and conditions. Some of the insurance covers have named drivers policy.</div>

                    <h5>9. Can I cancel my policy?</h5>
                    <div>This can depend on insurance companies. Generally, cancellation of the policy is only possible if vehicle ownership is transferred or it is deregistered. The refund on your original premium will also vary from insurance providers.</div>

                    <h5>10. What documents are required to be submitted?</h5>
                    <div>In order to issue a new car insurance policy, documents required are Passport with visa page/emirates ID, Date of birth, vehicle detail (make, model, year of manufacture, copy of vehicle registration, copy of your driver's license, confirmation that your vehicle is GCC specific, details of claims registered. For online applications electronic copies are required to be submitted.</div>

                    <h5>11. Can insurance and registration be in two different names?</h5>
                    <div>The policyholder will be both the registered vehicle owner and the main driver. Road and transport authority will only register the name stated on the insurance certificate.</div>

                    <h5>12. I Will my car insurance pay for the rental car if my car is in the garage/workshop?</h5>
                    <div>The rental vehicle can be made available to the insurer only if the clause is included in your policy schedule. The time period of a free rented car is generally between 7-10 days depending on insurance provider and type of policy until your car is fully repaired.</div>

                    <h5>13. In case of car being sold, is it possible to transfer insurance in the name of new owner?</h5>
                    <div>If a car is sold, the insurance doesn't get transferred to the new owner. This is not permitted because the terms and premium of the policy depends on the driver's experience.</div>

                    <h5>14. Is off-road included for my sports utility vehicle?</h5>
                    <div>The off-road cover will be only included for vehicles with off-road capability. Sports utility vehicle will not have this clause mentioned in the policy.</div>

                    <h5>15. Why do I need car insurance?</h5>
                    <div>According to Road and Transport Authority, it is illegal to drive without having a car insurance. You are required to get your car insured only then it can be registered.</div>

                    <h5>15. Will my driving history affect my online quote?</h5>
                    <div>Car insurance depends a lot on your driving history. Motorists with traffic violations or have caused serious accidents generally receive higher quotes compared to others with no traffic violation. Click2secure.me offers great rates with maximum savings.</div>

                    <h5>16. How is the price on insurance calculated?</h5>
                    <div>The price for your insurance is determined by your driving history, type of vehicle and the type of insurance cover etc. Log into Click2secure.me and get a quote for your car insurance.
                        <br />
                        This quote is valid for 30 days unless a voucher code has been applied on it.
                        <br />
                        If a voucher code has been applied on the quote, the quote will remain valid until the voucher code expires.
                    </div>

                    <h5>17. Why am I not able to get my car insured?</h5>
                    <div>There are certain situations when your application can be denied. This can happen in case the applicant is underage, new license holder, your car is very old are some of the common scenarios.</div>

                    <h5>18. Will I get a quote if I am holding a foreign license?</h5>
                    <div>You are only eligible to get car insurance quote if you are holding valid UAE license. Although you can convert your foreign license to valid UAE if you are holding license of any of these countries: GCC countries, Andorra, Austria, Belgium, Denmark, Finland, France, Germany, Greece, Iceland, Ireland, Italy, Liechtenstein, Luxemburg, Malta, Monaco, Netherlands, Norway, Portugal, San Marino, Spain, Sweden, Switzerland, UK, USA, Vatican City. After this you are eligible to apply for a quote.</div>

                    <h5>19. If I don’t have a registration number, will I still receive a quote?</h5>
                    <div>You will get a quote in spite of not having a registration number. All you have to do is fill in your details in the application form which will require your car details and upon completion you will get your quote online without registration number.</div>

                    <h5>20. Can I get a discount on my second vehicle?</h5>
                    <div>Yes, If you have applied with the same name on Click2secure.me you are eligible to get a discounted rate.</div>

                    <h5>21. Is there any fee on cancellation of my insurance policy?</h5>
                    <div>The rules and regulations for cancellation of insurance policy is different on Click2secure.me and should be referred with individual insurance provider.</div>

                    <h5>22. How can I renew my policy?</h5>
                    <div>Click2Secure.me will contact you when your insurance is about to expire. You will then be directed to our online portal where you will be able to renew your policy.</div>

                    <h5>23. What is the benefit on renewing with click2secure.me?</h5>
                    <div>This a very convenient option as all you details is in our database. Along with this, if the selected insurance provider is offering renewal benefits you will be also entitled for the same.</div>

                    <h5>24. Am I covered if I drive outside the county?</h5>
                    <div>General insurance coverage depends if it is listed in your schedule. The geographical area commonly included in the policy cover includes Oman, Bahrain, Saudi Arabia, Kuwait and Qatar. This option is only available with comprehensive insurance and in the case of third party liability, the cover is required to be purchased separately.</div>
                </div>
            </div>
        </div>
    </div>);
}