import "../css/CancellationPolicy.css";

export default function CancellationPolicy() {
    return (<div className="CancellationPolicy">
        <div className="section__content">
            <div className="header">
                <h1>Cancellation Policy<span>.</span></h1>

                <div className="body">
                    Customer can cancel their order/requested services within 24 hours; refunds will be made back to the payment solution used initially by the customer. Please allow for up to 45days for the refund transfer to be completed.
                </div>
            </div>
        </div>
    </div>);
}