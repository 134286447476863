import "../css/Disclaimer.css";

export default function Disclaimer() {
    return (<div className="Disclaimer">
        <div className="section__content">
            <div className="header">
                <h1>Disclaimer & Liability<span>.</span></h1>

                <div className="body">
                    <br />

                    <h5>Disclaimer</h5>
                    <div>While the Company endeavors to ensure that the information on the Website is correct, the Company does not warrant the accuracy and completeness of the material on the Website. <br /><br />

                        The Company may make changes to the material on the Website, or to the products and any other features described in it, at any time without notice. The material on the Website may be out of date, and the Company makes no commitment to update such material.<br />

                        This website has been provided by https://www.click2secure.me for informational purposes only.<br /><br />

                        Therefore, the content of this website is not intended to constitute and should not be construed as investment and/or financial advice or a personal recommendation to buy and/or to sell any investment and/or financial products and should not be relied upon in evaluating the merits of entering into any of the financial and/or investment products quoted in this website.<br /><br />

                        https://www.click2secure.me will not be liable for any loss, damages, claims, penalties and/or expenses arising from any financial and/or investment products quoted in this website.<br /><br />

                        The information contained in this website is public and was obtained from printed material, websites and other sources made available by insurers and other institutions.<br /><br />

                        https://www.click2secure.me cannot guarantee the accuracy of the information contained in this website and is not responsible for any discrepancy between the content of this website and any external sources.<br /><br />

                        This website will NOT deal with or provide any services or products to jurisdictions or persons subject to national or international sanctions programs, including without limitation jurisdictions or persons prohibited by the United States’ Office of Foreign Assets Control (OFAC).<br /><br />

                        This website may provide links to other websites. Those links are not under our control and we don't accept any responsibility for the content that you encounter when you leave click2secure.me</div>

                    <h5>Liability</h5>
                    <div>
                        The Company, any other party (whether or not involved in creating, producing, maintaining or delivering the Website), and any of the Company's group companies and the officers, directors, employees, shareholders or agents of any of them, exclude all liability and responsibility for any amount or kind of loss or damage that may result to you or a third party (including without limitation, any direct, indirect, punitive or consequential loss or damages, or any loss of income, profits, goodwill, data, contracts, use of money, or loss or damages arising from or connected in any way to business interruption, and whether in tort (including without limitation negligence), contract or otherwise) in connection with the Website in any way or in connection with the use, inability to use or the results of use of the Website, any websites linked to the Website or the material on such websites, including but not limited to loss or damage due to viruses that may infect your computer equipment, software, data or other property on account of your access to, use of, or browsing the Website or your downloading of any material from the Website or any websites linked to the Website<br /><br />

                        Nothing in this legal notice shall exclude or limit the Company's liability for: a).death or personal injury caused by negligence b).fraud; or c).misrepresentation as to a fundamental matter; or d).any liability which cannot be excluded or limited under applicable law.<br /><br />

                        If your use of material on the Website results in the need for servicing, repair or correction of equipment, software or data, you assume all costs thereof.

                        <h5>Jurisdiction</h5>
                        Any dispute or claim arising out of or in connection with the Website shall be governed by and construed in accordance with the laws of the United Arab Emirates, and the courts of the United Arab Emirates shall have exclusive jurisdiction in this respect.
                        <br /><br />
                        Multiple Bookings: The multiple bookings may result in multiple postings on the cardholder’s monthly statement.
                        <br /><br /><br />
                    </div>
                </div>
            </div>
        </div>
    </div>);
}