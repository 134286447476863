import React from 'react';
import ReactDOM from 'react-dom/client';

import './css/reset.css';
import './css/main.css';

import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Footer from './components/Footer/Footer';
import FAQ from './pages/FAQ';
import Disclaimer from './pages/Disclaimer';
import Glossary from './pages/Glossary';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import InsuranceTerms from './pages/InsuranceTerms';
import RefundPolicy from './pages/RefundPolicy';
import CancellationPolicy from './pages/CancellationPolicy';
import MotorInsurance from './pages/MotorInsurance';
import HealthInsurance from './pages/MedicalInsurance';
import HomeInsurance from './pages/HomeInsurance';
import CyberInsurance from './pages/CyberInsurance';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <BrowserRouter basename="https://www.click2secure.me/">  /c2s*/}
    <BrowserRouter>
      <App />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/glossary_definitions" element={<Glossary />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/motorInsurance" element={<MotorInsurance />} />
        <Route path="/healthInsurance" element={<HealthInsurance />} />
        <Route path="/cyberInsurance" element={<CyberInsurance />} />
        <Route path="/homeInsurance" element={<HomeInsurance />} />
        <Route path="/insurance_terms" element={<InsuranceTerms />} />
        <Route path="/refund_policy" element={<RefundPolicy />} />
        <Route path="/cancellation_policy" element={<CancellationPolicy />} />
        <Route path="*" element={<h1>404</h1>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);