import "../css/InsuranceTerms.css";

export default function InsuranceTerms() {
    return (<div className="InsuranceTerms">
        <div className="section__content">
            <div className="header">
                <h1>Insurance Terms & Conditions<span>.</span></h1>

                <div className="body">
                    PLEASE NOTE THAT THESE ARE IMPORTANT CONDITIONS AFFECTING YOUR RIGHTS AND SHOULD BE READ CAREFULLY.
                    <div>
                        <br />
                        These terms and conditions constitute a legal agreement entered between the Purchaser and the Broker (as defined below) and shall apply to any purchase of insurance products made through this platform.
                        <br /><br />
                        By accepting this agreement, the Purchaser agrees having also accepted Click2secure.me’s Website Terms &amp; Conditions and Privacy Policy, it being specified that neither Click2secure.me nor any of its owners, officers, employees or agents are participants in this agreement, nor shall they bear any liability whatsoever with respect to this agreement.

                        These terms and conditions were last updated on April 28, 2019.
                    </div>





                    <h5>Definitions</h5>
                    <div>
                        In this agreement unless the context indicates otherwise:
                        <br /><br />
                        Brokers, Shall mean the companies that are registered as a broker in Click2SecureME Systems and licensed to perform insurance activities in UAE. Broker regisration is by direct request from Insurance Company or via direct request from broker to IGS.
                        <br /><br />
                        “Policy” means any insurance policy available through this platform;
                        <br /><br />
                        “Purchaser” means any person or persons, jointly and severally, who are purchasing insurance products through this platform.
                    </div>

                    <h5>Purchase of Insurance Products</h5>
                    <div>
                        The Broker shall sell to the Purchaser, through this platform, the Policies selected by the Purchaser.
                        <br /><br />
                        In addition to these terms and conditions, the terms and conditions of the insurance provider supplying the Policy shall apply to any Policy purchased through this platform.
                        <br /><br />
                        By purchasing a Policy through this Platform, the Purchaser acknowledges having read and understood the insurance provider’s terms and conditions applicable to such Policy.
                        <br /><br />
                        Any information regarding a Policy presented on this platform is for information purposes only. The Purchaser should rely solely on the insurance provider’s terms and conditions in respect of any Policy, because it is those terms and conditions that will apply to the Purchaser’s insurance coverage.
                         </div>



                    <h5>Cancellation of Purchase</h5>
                    <div>
                        All purchases made through this platform shall be legally binding.
                        <br /><br />
                        Purchases may be cancelled by the Purchaser only in specific circumstances.
                        <br /><br />
                        The platform reserves the right to restrict refunds to being done only through the original mode of payment.
                    </div>
                    <h5>Acting as Intermediary Only</h5>
                    <div>
                        This platform and the Broker act as an intermediary between the Purchaser and the insurance provider offering a Policy through the platform. Neither the platform nor the Broker make any recommendation with respect to the suitability of any Policy, and any decision to purchase a particular Policy shall be the Purchaser’s sole responsibility.
                        <br /><br />
                        The Purchaser understands that any claims in relation to any Policy must be made directly to the insurance provider, and not through this platform or through the Broker.
                    </div>
                    <h5>Representations and Warranties of the Purchaser</h5>
                    <div>
                        By using this Platform to purchase a Policy, the Purchaser represents and warrants as follows: it is over the age of 18 and has the legal capacity to enter into this agreement; all of the information that it has supplied to this platform is true, correct, and not misleading; and it has disclosed to the Broker any circumstances, including other contracts or legal instruments to which the Purchaser may be bound, that would or may impede, impair or render illegal the Purchaser’s purchase of a Policy through this platform.
                    </div>
                    <h5>Payments</h5>
                    <div>
                        All payments in relation to a Policy purchased by the Purchaser are due on demand. All charges payable by the Purchaser shall be payable by credit card. We accept payments online using Visa and MasterCard credit or debit cards, in AED only.
                        <br /><br />
                        The Purchaser will not be entitled to set-off or withhold payment of any amounts due in terms of this agreement for any reason whatsoever.
                        <br /><br />
                        The Purchaser’s acceptance of this agreement will constitute irrevocable authority for the Broker to obtain authorization and/or payment on the Purchaser’s credit card or charge card.
                        <br /><br />
                        If any amount is not paid on the due date, the Broker may without prejudice to any rights it may have, charge interest on the overdue amount at a rate of 5% or the maximum amount allowed under the law, whichever is lower, and in the sole discretion of the Broker.
                        <br /><br />
                        A certificate of any director, manager or accountant of the Broker as to any amount owed by the Purchaser to the Broker shall constitute prima facie proof of that amount.
                        <br /><br />
                        It shall be the responsibility of the cardholder to retain a copy of transaction records as well as a copy of the terms and conditions set forth in this agreement.
                        <br /><br />
                        Once the payment is made, the confirmation notice will be sent to the client via email within 24 hours of receipt.
                     </div>
                    <h5>Delivery Policy</h5>
                    <div>
                        All documents relating to insurance products purchased by the Purchaser, including the policy itself, shall be delivered to the Purchaser exclusively by e-mail.
                        <br /><br />
                        Limitation of Liability / Indemnity of the Broker by Purchaser
                        <br /><br />
                        Neither the Broker nor any of its directors, officers, employees, service providers or agents shall be liable for any loss or damage, whether direct, indirect, consequential or otherwise arising from the purchase by the Purchaser of any insurance products through this platform.
                        <br /><br />
                        The Broker, its directors, officers, employees, affiliates or agents are accordingly indemnified by the Purchaser against any claim of any nature whatsoever and howsoever arising from any damages or loss which might be instituted against the Broker, its directors, officers, employees, affiliates or agents arising from or connected with or pursuant to the purchase of insurance products contemplated in this agreement.
                    </div>
                    <h5>General</h5>
                    <div>
                        This document contains the entire agreement between the parties regarding the matters contained herein and no party shall be bound by any undertakings, representations, warranties, promises or the like not recorded in writing.
                        <br /><br />
                        If any provision of this agreement is found by a court of law to be invalid or void such provision will be severed from the agreement, without affecting the remainder of the provisions hereof.
                        <br /><br />
                        No extension, latitude or other indulgence that may be given or are allowed by any party in respect of performance of any obligation hereunder, and no delay or forbearance in the enforcement of any party arising from this agreement, and no single or partial exercise of any right of any party under this agreement will in any circumstances be construed as implied consent or election by such party or operate as a waiver or a novation of or otherwise affect any party’s rights in terms of or arising from this agreement or stop or preclude any such party from enforcing at any time and without notice, strict and punctual compliance with each and every provision or term hereof.
                        <br /><br />
                        This agreement and all matters or disputes arising there from or incidental thereto shall be governed and construed in accordance with the laws of the United Arab Emirates. The Purchaser consents to the jurisdiction of the courts of the United Arab Emirates with respect to any disputes arising out of this agreement.
                        <br /><br />
                        Multiple Bookings: The multiple bookings may result in multiple postings on the cardholder’s monthly statement
                    </div>
                </div>
            </div>
        </div>
    </div>);
}