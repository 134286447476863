import "../css/Terms.css";

export default function Terms() {
    return (<div className="Terms">
        <div className="section__content">
            <div className="header">
                <h1>Terms & Conditions<span>.</span></h1>

                <div className="body">
                    <div>
                        <br /><br />
                        This website Click2Secure.me is owned and provided by "Infinit Global Solutions", which is domiciled in the United Arab Emirates.
                        <h5>IMPORTANT LEGAL NOTICE</h5>

                        <strong>ATTENTION:</strong> This legal notice applies to the entire contents of the Website under the domain name https://www.click2secure.me and to any correspondence by e-mail between us and you. Please read these terms carefully before using the Website. Using the Website indicates that you accept these terms and the privacy policy regardless of whether or not you choose to register with us. If you do not accept these terms, do not use the Website. This notice is issued by https://www.click2secure.me .
                    </div>


                    <h5>Introduction</h5>
                    <div>
                        Visa or MasterCard debit and credit cards in AED will be accepted for payment.
                        <br /><br />
                        We will not trade with or provide any services to OFAC and sanctioned countries.
                        <br /><br />
                        Cardholder must retain a copy of transaction records and policies and rules.
                        <br /><br />
                        User is responsible for maintaining the confidentiality of his account.
                        <br /><br />
                        Certain areas of the Website are only open to you if you register with us by providing your email, first name and nick name
                        <br /><br />
                        By accessing any part of the Website, you shall be deemed to have accepted this legal notice in full. If you do not accept this legal notice in full, you must leave the Website immediately. If you are under the age of 18 or otherwise do not have the capacity to accept this legal notice, you must leave the Website immediately.
                        <br /><br />
                        The Website may revise this legal notice at any time by updating this posting. You should check the Website from time to time to review the then current legal notice, because it is binding on you. Certain provisions of this legal notice may be superseded by expressly designated legal notices or terms located on particular pages at the Website.
                        <br /><br />
                        You are permitted to print and download extracts from the Website on the following basis:
                        <ul>
                            <li> a).no documents or related graphics on the Website are modified in any way;</li>

                            <li> b).no graphics on the Website are used separately from the corresponding text; and</li>

                            <li> c).the Company's copyright and trade mark notices and this permission notice appear in all copies.</li>
                        </ul>
                        <br /><br />
                        Unless otherwise stated, the copyright and other intellectual property rights in all material on the Website (including without limitation photographs and graphical images) are owned by the Company or its licensors. For the purposes of this legal notice, any use of extracts from the Website other than in accordance with clause 1.4 for any purpose is prohibited. If you breach any of the terms in this legal notice, your permission to use the Website automatically terminates and you must immediately destroy any downloaded or printed extracts from the Website.
                        <br /><br />
                        Subject to clause 1.4, no part of the Website may be reproduced or stored in any other website or included in any public or private electronic retrieval system or service without the Company's prior written permission.
                        <br /><br />
                        Any rights not expressly granted in these terms are reserved.
                    </div>
                    <h5>Service access</h5>
                    <div>
                        While the Website endeavors to ensure that the it is normally available 24 hours a day, the Company shall not be liable if for any reason the Website is unavailable at any time or for any period.
                        <br /><br />
                        Access to the Website may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons beyond the Company's control.
                    </div>
                    <h5>Visitor material and conduct</h5>
                    <div>
                        Other than personally identifiable information, which is covered under our Privacy Policy, any material you transmit or post to the Website shall be considered non-confidential and non-proprietary. The Website shall have no obligations with respect to such material. The Website and its designees shall be free to copy, disclose, distribute, incorporate and otherwise use such material and all data, images, sounds, text and other things embodied therein for any and all commercial or non-commercial purposes.
                        <br /><br />
                        You are prohibited from posting or transmitting to or from the Website any material:

                        <ul>
                            <li> a). that is threatening, defamatory, obscene, indecent, seditious, offensive, pornographic, abusive, liable to incite racial hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of confidence, in breach of privacy or which may cause annoyance or inconvenience; or</li>

                            <li> b). for which you have not obtained all necessary licenses and/or approvals; or</li>

                            <li> c). which constitutes or encourages conduct that would be considered a criminal offence, give rise to civil liability, or otherwise be contrary to the law of or infringe the rights of any third party, in any country in the world; or</li>

                            <li> d). which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data).</li>
                        </ul>
                        <br /><br />
                        You may not misuse the Website (including, without limitation, by hacking).
                        <br /><br />
                        The Website shall fully co-operate with any law enforcement authorities or court order requesting or directing the Company to disclose the identity or locate anyone posting any material in breach of clause 1.11 or clause 1.12.
                    </div>
                    <h5>Links to and from other websites</h5>
                    <div>
                        Links to third party websites on the Website are provided solely for your convenience. If you use these links, you leave the Website. The Company has not reviewed all of these third party websites and does not control and is not responsible for these websites or their content or availability. The Company therefore does not endorse or make any representations about them, or any material found there, or any results that may be obtained from using them. If you decide to access any of the third party websites linked to the Website, you do so entirely at your own risk.
                        <br /><br />
                        If you would like to link to the Website, you may only do so on the basis that you link to, but do not replicate, the home page of the Website, and subject to the following conditions:


                        <ul>
                            <li>a). you do not remove, distort or otherwise alter the size or appearance of the https://www.click2secure.me logo;</li>

                            <li>b). you do not create a frame or any other browser or border environment around the Website;</li>

                            <li>c). you do not in any way imply that the Website is endorsing any products or services other than its own;</li>

                            <li>d). you do not misrepresent your relationship with the Company nor present any other false information about the Company;</li>

                            <li>e). you do not otherwise use any trade marks displayed on the Website without express written permission from the Company;</li>

                            <li>f). you do not link from a website that is not owned by you; an//d</li>
                        </ul>
                        <br /><br />
                        g).your website does not contain content that is distasteful, offensive or controversial, infringes any intellectual property rights or other rights of any other person or otherwise does not comply with all applicable laws and regulations.
                        <br /><br />
                        The Company expressly reserves the right to revoke the right granted in clause 1.15 for breach of these terms and to take any action it deems appropriate.
                        <br /><br />
                        You shall fully indemnify the Company for any loss or damage suffered by the Company or any of its group companies for breach of clause 1.15.]
                    </div>
                    <h5>Registration</h5>
                    <div>
                        Each registration is for a single user only. The Company does not permit you to share your user name and nickname with any other person nor with multiple users on a network.
                        <br /><br />
                        Responsibility for the security of any passwords issued rests with you.
                        <br /><br />
                    </div>
                    <div>
                       
                           “CLICK TO SECURE INSURANCE TECHNOLOGY LLC” maintains the https://www.click2secure.me/ Website ("Site").<br />
                           “United Arab of Emirates is our country of domicile” and stipulate that the governing law is the local law.<br />
                           “The displayed price and currency at the checkout page, will be the same price and currency printed on the Transaction Receipt and the amount charged to the card will be shown in your card currency”.<br />
                        
                    </div>
                </div>
            </div>
        </div>
    </div>);
}
