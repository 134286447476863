import "./Footer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/logo.png";
import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
    return (<footer>
        <div className="section__content">
            <div className="column onlyweb">
                <img src={logo} alt="logo" width={150} />
            </div>
            <div className="column">
                <div className="footer__header">Solutions</div>
                <Link to="/motorInsurance">
                    <div className="footer__item">Motor Insurance</div>
                </Link>
                <Link to="/healthInsurance">
                    <div className="footer__item">Health Insurance</div>
                </Link>
                <Link to="/homeInsurance">
                    <div className="footer__item">Home Insurance</div>
                </Link>
                <Link to="/cyberInsurance">
                    <div className="footer__item">Cyber Insurance</div>
                </Link>
            </div>
            <div className="column">
                <div className="footer__header">Information</div>
                <Link to="/disclaimer">
                    <div className="footer__item">Disclaimer</div>
                </Link>
                <Link to="/glossary_definitions">
                    <div className="footer__item">Glossary & Definitions</div>
                </Link>
                <Link to="/privacy_policy">
                    <div className="footer__item">Privacy Policy</div>
                </Link>
                <Link to="/refund_policy">
                    <div className="footer__item">Refund Policy</div>
                </Link>
                <Link to="/cancellation_policy">
                    <div className="footer__item">Cancellation Policy</div>
                </Link>
                <Link to="/terms">
                    <div className="footer__item">Terms & Conditions</div>
                </Link>
                <Link to="/insurance_terms">
                    <div className="footer__item">Insurance T&C</div>
                </Link>
            </div>
            <div className="column">
                <div className="footer__header">Company</div>
                <Link to="/about">
                    <div className="footer__item">About us</div>
                </Link>
                <Link to="/faq">
                    <div className="footer__item">Car Insurance FAQ</div>
                </Link>
            </div>
            <div className="column social">
                <div className="footer__header">Follow us</div>
                <a className="footer__item" href="https://www.linkedin.com/company/click2secure/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} size="2xl" />
                </a>
                <a className="footer__item" href="https://www.facebook.com/click2secure/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="2xl" />
                </a>
                <a className="footer__item" href="https://www.instagram.com/click2secure/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="2xl" />
                </a>
                <a className="footer__item" href="https://twitter.com/click2secureme" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faTwitter} size="2xl" />
                </a>
            </div>
            <div className="column onlymobile">
                <img src={logo} alt="logo" width={150} />
            </div>
        </div>
        <div className="section__content contact_us contact-section">
            <div className="column  ">
                <div className="footer__header" >Contact Us </div>
                CLICK TO SECURE INSURANCE TECHNOLOGY L.L.C.
                <p>1017 - 10th floor - Madinat Zayed Office Tower - Muroor Road - Abu Dhabi <br />
                    Email: <a href="mailto:info@click2secure.me">info@click2secure.me</a> <br />
                    Number: <a href="tel:+971509887138">+971 50 988 7138</a> <br />
                    PO Box 2162</p>
            </div>

        </div>

        <div className="copyright">
            Copyright © {new Date().getFullYear()} Click To Secure Insurance Technology LLC. All rights reserved
        </div>
    </footer>);
}

export default Footer;