import React from 'react'
import "./Card.css";

interface CardProps {
    icon: JSX.Element,
    header: string,
    subheader: string,
    style?: React.CSSProperties
}

export default function Card(props: CardProps) {
    return (
        <div className="Card" style={props.style}>
            <div className="icon">
                {props.icon}
            </div>
            <div className="header">{props.header}</div>
            <div className="subheader">{props.subheader}</div>
            <div className="button">Explore our solutions &#8594;</div>
        </div>
    );
}