import React, { useEffect, useState } from 'react';
import logo from "./assets/images/logo.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

import { Link, useLocation } from 'react-router-dom';

const menu: any = {
 
}

function App() {

  const [sideMenu, setSideMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState<null | number>(null);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <header>
        <div className="section__content">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logo" width={150} />
            </Link>
          </div>
          {Object.keys(menu).map((item) => <div className="menu-item onlyweb">
            {menu[item].link ? <Link to={menu[item].link} target="_blank"><span>{item}</span></Link> : <span>{item}</span>}

            {!menu[item].link && <div className="dropdown">
              {Object.keys(menu[item]).map((item2) => <Link to={menu[item][item2].link}>
                <div className="dropdown__item">
                  {menu[item][item2].icon}
                  {item2}
                </div>
              </Link>)}
            </div>}
          </div>)}

          <div className="mobile-menu-icon onlymobile" onClick={() => setSideMenu(true)}>
            <FontAwesomeIcon icon={faBars} color='#222222' />
          </div>
        </div>

        <div className={"full-screen-menu" + (sideMenu ? " active" : "")} style={{ top: window.screenY }}>
          {Object.keys(menu).map((item, index) => <div className="mobile-menu-item">
            {menu[item].link ? <Link to={menu[item].link} target="_blank"><span>{item}</span></Link> : <span onClick={() => setActiveMenu(index)}>{item}</span>}

            {index === activeMenu && <div className="dropdown">
              {Object.keys(menu[item]).map((item2) => <Link to={menu[item][item2].link} onClick={() => setSideMenu(false)}>
                <div className="dropdown__item">
                  {menu[item][item2].icon}
                  {item2}
                </div>
              </Link>)}
            </div>}
          </div>)}
          <FontAwesomeIcon icon={faXmark} onClick={() => setSideMenu(false)} size="xl" />
        </div>
      </header>

      <div style={{ height: 82 }} />
    </div>
  );
}

export default App;
