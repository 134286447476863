import "../css/About.css";

export default function About() {
    return (<div className="About">
        <div className="section__content">
            <div className="header">
                <h1>About us<span>.</span></h1>

                <div className="body">
                    <p>
                        Click2Secure Me is the first Insure-Tech Platform where creators are building the digital insurer and helps insurers put their digital transformation on the fast track in Middle East Region.
                    </p>
                    <p>
                        For insurers, the digital age presents both threats and opportunities. Customers are less loyal, and more Insures are entering the market with disruptive ideas. To remain relevant, insurers must redefine their products, services and business models around the customer.
                    </p>
                    <p>
                        The challenge is that most insurers are bogged down with legacy systems and large modernization projects that consume resources and hamper innovation. As a result, IT teams can’t cope with growing business demands and the accelerating pace of change.
                    </p>
                    <p>
                        Leading insurance companies use Click2Secure Me to quickly build and continuously improve mobile and web apps that drive business innovation. Our platform is uniquely designed to support rapid, iterative development by a broad range of users while ensuring that IT retains control.
                    </p>
                    <p>
                        Click2Secure Me integrate seamlessly with existing legacy systems using our Connector Kit and messaging services based on industry protocols such as Acord. Easily and continuously adapt your applications to keep pace with changing business and market requirements.
                    </p>
                </div>
            </div>
        </div>
    </div>);
}