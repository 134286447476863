import "../css/RefundPolicy.css";

export default function RefundPolicy() {
    return (<div className="RefundPolicy">
        <div className="section__content">
            <div className="header">
                <h1>Refund Policy<span>.</span></h1>

                <div className="body">
                    <div>
                        Any refund related issues please send an email to info@click2secure.me by furnishing the following details: -
                        <ul>
                            <li>» Attention Finance department, – (online payment refund)</li>
                            <li>» Contact Number (preferred mobile No.)</li>
                            <li>» Mention policy number</li>
                            <li>» Date transaction was processed</li>
                            <li>» Amount of transaction</li>
                            <li>» Reason for refund</li>
                        </ul>
                        <br />
                        The refund process will take 15-25 working days and refund credit will be reflected in client’s next credit card statement.
                        <br /><br />
                        Refunds will be done only through the Original Mode of Payment
                    </div>
                    <h5>What is online payment facility?</h5>
                    <div>
                        Online premium payment is a service extended to our customers whereby they need not come to our office for payment of premium. They can pay online from wherever they are at their own convenience. The payment can be made vide the internet from any PC by logging on to Click2Secure.me website www.click2secure.me

                        Documents can be printed online and same will be emailed to you on your given email address in PDF format
                    </div>
                    <h5>Personal lines Products</h5>
                    <div>
                        <ul>
                            <li>» Travel Insurance</li>
                            <li>» Motor Insurance</li>
                            <li>» Home multi cover insurance
                            </li><li>» PA insurance</li>
                            <li>» Haj / Umrah insurance</li>
                        </ul>

                    </div>
                    <h5>Service Contact Address</h5>
                    <div>
                        Click 2 Secure Me FZ-LLC<br />
                        P O Box 2162<br />
                        Dubai, United Arab Emirates.<br />
                        Telephone # 04 449 8580<br />
                        Email info@click2secure.me
                    </div>
                </div>
            </div>
        </div>
    </div>);
}