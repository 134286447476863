import React from 'react'
import "./Testimonial.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';

interface TestimonialProps {
    logo: string,
    text: string,
    company: string,
    style?: React.CSSProperties
}

export default function Testimonial(props: TestimonialProps) {
    return (<div className="Testimonial">
        <FontAwesomeIcon icon={faQuoteRight} size="2xl" color="#0085FF" />
        <div className="content">
            <div className="text">
                {props.text}
            </div>
            <div className="company-content">
                {/* <div className="title">
                    {props.company}
                </div> */}
                <div className="logo">
                    <img src={props.logo} alt="" />
                </div>
            </div>
        </div>
    </div>);
}