import React, { useState } from 'react';

import InsuranceImage from "../assets/images/insurance.webp";
import SupportImage from "../assets/images/support.webp";
import FirstPeopleImage from "../assets/images/people-1.png";
import SecondPeopleImage from "../assets/images/people-2.png";
import quote from "../assets/images/quote.png";
import roadmap from "../assets/images/roadmap.webp";
import qa from "../assets/images/qa.webp";

import features1 from "../assets/images/features-1.webp";
import features2 from "../assets/images/features-2.webp";
import features3 from "../assets/images/features-3.webp";
import features4 from "../assets/images/features-4.webp";
import features5 from "../assets/images/features-5.webp";

import testimonial1 from "../assets/images/testimonial-1.svg";
import testimonial2 from "../assets/images/testimonial-2.png";
import testimonial3 from "../assets/images/testimonial-3.png";
import testimonial4 from "../assets/images/testimonial-4.png";

/* logos */
import company1 from "../assets/images/logos/abnic.svg";
import company2 from "../assets/images/logos/allianz-logo.svg";
import company3 from "../assets/images/logos/Dubai Business Corp.png";
import company4 from "../assets/images/logos/Fidelity United.png";
import company5 from "../assets/images/logos/nextcare.png";
import company6 from "../assets/images/logos/rakbank with arabic.gif";
import company7 from "../assets/images/logos/RAKEZ.svg";
import company8 from "../assets/images/logos/RAKNIC.png";
import company9 from "../assets/images/logos/SMART-UMM-AL-QUWAIN0-LOGO1-1.png";
import company10 from "../assets/images/logos/UAQFTZ.png";
import company11 from "../assets/images/logos/uaqu logo with bg.jpg";

/* logos */
import payment1 from "../assets/images/logos/MasterCard_Logo.png";
import payment2 from "../assets/images/logos/UnionPay_logo.webp";
import payment3 from "../assets/images/logos/Visa_logo.png";


import Card from '../components/card/Card';
import Testimonial from '../components/Testimonial/Testimonial';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarBattery, faHouseCrack, faLaptopCode, faLaptopMedical } from '@fortawesome/free-solid-svg-icons';

const cards = [
    { icon: <FontAwesomeIcon icon={faCarBattery} size="5x" color="#0085ff" />, header: "Motor Insurance Solutions", subheader: "Expand your reach by offering customers a seamless online experience for motor insurance policies. Our platform enables you to provide TPL and full risk coverage with attractive discounts and rewards while maintaining the highest security standards." },
    { icon: <FontAwesomeIcon icon={faLaptopMedical} size="5x" color="#0085ff" />, header: "Health Insurance Solutions", subheader: "Digitally transform your health insurance offerings with customizable coverage options for Dubai & Northern Emirates visa residents. Leverage our platform to provide user-friendly and secure services to a wider audience." },
    { icon: <FontAwesomeIcon icon={faHouseCrack} size="5x" color="#0085ff" />, header: "Home Insurance Solutions", subheader: "Stay competitive by enabling customers to purchase home insurance policies online. Our advanced technology helps you streamline the policy purchasing process while offering attractive discounts and rewards in a secure environment." },
    { icon: <FontAwesomeIcon icon={faLaptopCode} size="5x" color="#0085ff" />, header: "Cyber Insurance Solutions", subheader: "Meet the growing demand for cyber insurance with our state-of-the-art platform. Offer comprehensive coverage, competitive pricing, and superior security to stand out in a rapidly evolving market." },
];

const features = [
    { image: features1, header: "Paperless Experience", subheader: "Reduce clutter and simplify document management with our entirely paperless approach." },
    { image: features2, header: "Security & Compliance", subheader: "Ensure data protection and regulatory compliance with our robust security measures." },
    { image: features3, header: "Seamless Integration", subheader: "Connect effortlessly with existing systems using our Connector Kit and ACORD-based messaging services." },
    { image: features4, header: "Customizable Platform", subheader: "Adapt our platform to your unique needs and offer tailored insurance solutions to your customers." },
    { image: features5, header: "Ongoing Support", subheader: "Benefit from our friendly and responsive customer service team, available seven days a week." },
];

const testimonials = [
    { logo: testimonial1, company: "Al Buhaira National Insurance", text: "The entire user experience is really straightforward. Minimal instructions were needed when we started using it." },
    { logo: testimonial2, company: "Tokio Marine Insurance Group", text: "We love the platform and all the built-in features from end users. Great service from Click2Secure." },
    { logo: testimonial3, company: "Fidelity United", text: "For us, Click2Secure has completely changed the game." },
    { logo: testimonial4, company: "AXA Insurance", text: "The Click2Secure team always maintains a cool demeanor, is eager to assist, and goes above and above when their assistance is needed." }
];

const companies = [
    company1,
    company2,
    company3,
    company4,
    company5,
    company6,
    company7,
    company8,
    company9,
    company10,
    company11,
];


const payment = [
    payment1,
    payment2,
    payment3,
];

export default function Home() {

    const [showAllTestimonial, setShowAllTestimonial] = useState(false);
    const [showAllClients, setShowAllClients] = useState(false);

    return (<>
        <section id="first-section">
            <div className="section__content">
                <div className="people">
                    <img src={FirstPeopleImage} alt="" />
                    <img src={SecondPeopleImage} alt="" />
                </div>
                <div className="header">
                    <h1>Revolutionizing the Insurance Industry<br />with Cutting-Edge Solutions</h1>
                    <h6>Empowering Insurers with Advanced Technology for Seamless and Secure Online Insurance Services</h6>
                </div>

                <div className="input-box-outline">
                    <div className="input-box">
                        <input type="email" placeholder="Enter your work email" />
                        <div className="button">Request a demo</div>
                    </div>
                </div>

                <img src={InsuranceImage} alt="" className='image' />

                <div className="text subheader">
                    Welcome to Click to Secure Insurance Technology, the leading Insurtech platform dedicated to helping insurance providers in the Middle East offer seamless, secure, and efficient online insurance services. Our state-of-the-art platform is designed to transform the insurance industry by enabling insurers to adapt and thrive in a competitive digital landscape.<br />Experience the benefits of our cutting-edge technology and comprehensive solutions tailored to your needs.
                </div>
            </div>
        </section>

        <section id="second-section">
            <div className="section__content">
                <div className="section-header">
                    Insurtech reimagined
                </div>
                <div className="header">
                    <h1>Innovative Insurance Solutions for the Digital Age</h1>
                    <h6>Embrace the power of technology with our end-to-end digital insurance products</h6>
                </div>

                <div className="cards">
                    {cards.map((item) => <Card icon={item.icon} header={item.header} subheader={item.subheader} />)}
                </div>
            </div>
        </section>

        <section id="third-section">

            <img className="quote" src={quote} alt="" />

            <div className="section__content">
                <div className="section-header">
                    Features
                </div>
                <div className="header">
                    <h1>Partnering with Click To Secure – Your Key to Success</h1>
                    <h6>Discover the benefits of joining forces with the leader in insurance technology</h6>
                </div>

                <div className="list">
                    {features.map((item) => <div className="row">
                        <div className="row__side-content" style={{ width: "35%" }}>
                            <div className="row__header">
                                {item.header}
                            </div>
                            <div className="row__subheader">
                                {item.subheader}
                            </div>
                            <div className="row__button">
                                Request a demo &#10140;
                            </div>
                        </div>
                        <div className="row__side-content">
                            <img src={item.image} alt="" />
                        </div>
                    </div>)}
                </div>
            </div>
        </section>

        <section id="fourth-section">

            <div className="section__content">
                <div className="section-header">
                    Testimonials
                </div>
                <div className="header">
                    <h1>Trusted by Leading Insurers</h1>
                    <h6>Hear from our satisfied partners who have embraced digital transformation</h6>
                </div>

                <div className="list">
                    {testimonials.map((item, index) => (index < 2 || (index >= 2 && showAllTestimonial)) && <Testimonial logo={item.logo} text={item.text} company={item.company} />)}
                </div>

                {!showAllTestimonial && <div className="cta-button" onClick={() => setShowAllTestimonial(true)}>
                    Read More Testimonials
                </div>}
            </div>
        </section>

        <section id="fifth-section">
            <div className="section__content">
                <div className="divider-text">
                    <span>
                        Trusted by reinsurance brokers across the region
                    </span>
                </div>

                <div className="section__content">
                    <div className="header">
                        <h1>Our Esteemed Clients</h1>
                        <h6>Collaborating with top-tier insurance providers for a brighter digital future</h6>
                        <div className="body">
                            At Click To Secure, we are proud to partner with leading insurance companies that recognize the potential of our platform to transform their businesses. Our clients trust us to provide exceptional technology solutions and the highest level of support, allowing them to remain competitive and meet their customers' evolving needs.
                        </div>
                    </div>
                </div>
            </div>
            {showAllClients && <div className="companies-overflow">
                <div className="companies-list">
                    {companies.map((item) => <img className="company" src={item} height={50} alt="" />)}
                </div>
                <div className="companies-list">
                    {companies.map((item) => <img className="company" src={item} height={50} alt="" />)}
                </div>
            </div>}

            {!showAllClients && <div className="companies-overflow">
                <div className="companies-list withoutscroll">
                    {companies.map((item, index) => (companies.length / 2 <= index) && <img className="company" src={item} height={50} alt="" />)}
                </div>
            </div>}

            {!showAllClients && <div className="cta-button" onClick={() => setShowAllClients(true)}>
                View all clients
            </div>}
        </section>

        

        <section id="sixth-section">
            <div className="section__content">
                <div className="section-header">
                    Roadmap
                </div>
                <div className="header">
                    <h1>Our Roadmap to Success</h1>
                    <h6>Charting a course to revolutionize the insurance industry</h6>
                    <div className="body">
                        Our journey began with a simple mission: to empower insurers with cutting-edge technology that would help them adapt and thrive in the digital age. Since our inception, we have continuously expanded our platform's capabilities and collaborated with our clients to develop innovative insurance solutions. We are dedicated to maintaining our position at the forefront of insurance technology and providing unparalleled value to our partners.
                    </div>
                </div>
            </div>
            <img src={roadmap} width="100%" alt="" />
        </section>

        <section id="seventh-section">
            <div className="section__content">
                <div className="side-content">
                    <img src={qa} alt="" width="100%" />
                </div>
                <div className="side-content">
                    <div className="section-header">
                        Our development
                    </div>
                    <div className="header">
                        <h1>Excellence in Development</h1>
                        <h6>Harnessing the power of technology to redefine the insurance experience</h6>
                        <div className="body">
                            At Click to Secure, our team of skilled developers and engineers are dedicated to creating advanced and secure technology solutions that keep insurers ahead of the curve. By utilizing the latest tools, methodologies, and industry best practices, we consistently deliver high-quality, user-friendly, and scalable insurance products that drive business growth.
                        </div>
                        <div className="button">
                            Learn More About Our Development Process
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="eighth-section">
            <div className="section__content">
                <div className="row">
                    <div className="row__side-content" style={{ width: "45%" }}>
                        <div className="row__header">
                            World-Class Customer Care
                        </div>
                        <div className="row__subheader2">
                            Here to support you every step of the way
                        </div>
                        <div className="row__subheader">
                            Our commitment to exceptional customer care sets us apart from the competition. We understand the importance of responsive, reliable, and knowledgeable support in helping our clients succeed. Our friendly customer service team is available seven days a week to answer any questions, address concerns, and assist with the seamless integration and utilization of our platform.
                        </div>
                        <a href="#contact-us" className="row__button">
                            Get in Touch with Our Support Team
                        </a>
                    </div>
                    <div className="row__side-content">
                        <img src={SupportImage} alt="" />
                    </div>
                </div>
            </div>
        </section>
        <section id="fifth-section">
            <div className="section__content">
                <div className="divider-text">
                    <span>
                        Accepted payment cards
                    </span>
                </div>

                <div className="section__content">
                    <div className="header">
                        <h1>We accept the following cards : </h1>
                    </div>
                </div>
            </div>
           

            {!showAllClients && <div className="companies-overflow">
                <div className="companies-list withoutscroll">
                    {payment.map((item) => <img className="company" src={item} height={50} alt="" />)}
                </div>
            </div>}

    
        </section>
        <section id="contact-us">
            <div className="section__content">
                <div className="section-header">
                    Contact us
                </div>

                <div className="form">
                    <input placeholder="Name" type="text" />
                    <input placeholder="Email address" type="email" />
                    <input placeholder="Mobile number" type="tel" />
                    <textarea placeholder="Message" />
                    <div className="button">Submit</div>
                </div>
            </div>
        </section>
    </>);
}