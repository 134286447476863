import "../css/Glossary.css";

export default function Glossary() {
    return (<div className="Glossary">
        <div className="section__content">
            <div className="header">
                <h1>Glossary & Definitions<span>.</span></h1>

                <div className="body">
                    <h5>APR</h5>
                    <div>This stands for Annual Percentage Rate and is the interest charged on your purchases</div>

                    <h5>Authorized User</h5>
                    <div>A person who is issued with a credit card and has full charging privileges on the main cardholder's account, but is not responsible for the repayment of the balance on the account</div>

                    <h5>Arrangement fee</h5>
                    <div>A charge by a lender for setting up a loan. The fee is payable in advance and is generally applicable to mortgage loans.</div>

                    <h5>Authorization</h5>
                    <div>Approved transactions for which funds have been set aside. Once a transaction is transmitted through the credit card system, the un-posted amount will be replaced by an actual charge. If the charge is cancelled and not processed through the credit card system, the un-posted transaction will be removed from your account approximately 14 days after it was authorized</div>

                    <h5>Annual fee</h5>
                    <div>An annual fee charged by a credit card company for use of a credit card</div>

                    <h5>Available Credit</h5>
                    <div>Your current credit limit less your current outstanding balance and any authorized but un-posted transactions (including Temporary Authorizations).</div>

                    <h5>Balance transfer</h5>
                    <div>A balance transfer is the transfer of (part of) the balance (either money or credit) in an account to another account, often held at another institution</div>

                    <h5>Card issuer</h5>
                    <div>The bank, building society or store whose name is on your card</div>

                    <h5>Chargeback</h5>
                    <div>A charge-back is a transaction returned through a credit card</div>

                    <h5>Chip and PIN</h5>
                    <div>Your unique four-figure number which you must use when you spend with your card.</div>

                    <h5>Cash Withdrawal (ATM)</h5>
                    <div>Use your Credit Card and your 4-digit personal identification number (PIN) at hundreds of thousands of ATMs in most countries that display the MasterCard®, Visa® or American Express® logos</div>

                    <h5>Credit limit</h5>
                    <div>The maximum amount you may owe through spending on the card. If you go over this limit, your card may be refused and you may also have to pay extra charges.</div>

                    <h5>Credit rating</h5>
                    <div>This is a scoring system that lenders issue people with to determine how credit worthy they are</div>

                    <h5>Cashback</h5>
                    <div>Cash rebates on purchases made on the card by the card issuer</div>

                    <h5>Currency Account</h5>
                    <div>This is an account that comes in any currency offered by a bank and it could be in AED, GBP, EUR, USD and many more.</div>

                    <h5>Current Account</h5>
                    <div>This is an account type that offers a cheque book facility and is more transactional hence the salary is credited to a current account. This account does not accrue interest in principle except for some banks who offer interest as a bonus.</div>

                    <h5>Debit card</h5>
                    <div>A debit card allows you to make purchases and withdraw cash by using funds from your bank account. These funds are automatically withdrawn from the connected account. They act as an alternative to cash and cheques.</div>

                    <h5>Deposit Account</h5>
                    <div>This is a type of account where you freeze a certain sum of money for a specific period which is agreed by yourself and the bank and on that basis, you will get interest paid to you at the end of the agreed period. This type of account can be broken before the agreed period, however, banks charge for early deposit upliftment.</div>

                    <h5>FX rate</h5>
                    <div>Foreign exchange rate - this is the % fee that the bank charges the cardholder on retail purchases internationally</div>

                    <h5>Grace period</h5>
                    <div>The grace period is the time during which you are allowed to pay your bill without being charged interest rate on the outstanding balance.</div>

                    <h5>Main cardholder</h5>
                    <div>The person responsible for the repayment of the account balance. This person is listed first on the account.</div>

                    <h5>Other Fees</h5>
                    <div>This relates to any fees which are not linked to the monthly account fee. So it will relate to ATM fees, Transfer fees, Teller fees or any other fee type.</div>

                    <h5>Payment Due Date</h5>
                    <div>The date on which your next payment is due. At least your minimum payment must be cleared onto your account before this date to remain in compliance with your Credit Card Agreement. Accounts with payments received after the due date may be subject to late fees.</div>

                    <h5>Terms and conditions</h5>
                    <div>These are the terms of the contract signed between you and the issuing institution</div>

                    <h5>Transaction date</h5>
                    <div>The date goods and services are purchased, or the date a cash advance is made.</div>

                    <h5>Travel insurance</h5>
                    <div>Covers you for cancellation, medical care and loss of possessions while on holiday</div>

                    <h5>Underwriting</h5>
                    <div>Where an insurance company takes into account known facts like your age, sex and health, in order to assess the likelihood of you making a claim on the policy</div>

                    <h5>Valuation</h5>
                    <div>Carried out by a professional surveyor to establish how much a property is worth and whether it is suitable to lend a mortgage on</div>
                </div>
            </div>
        </div>
    </div>);
}